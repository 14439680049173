import React, {Suspense} from "react";
import "./App.css";
import Sidebar from "./components/sidebar/Sidebar.jsx";
// import Home from "./components/home/Home";
// import About from "./components/about/About";
// import Resume from "./components/resume/Resume";
// import Contact from "./components/contact/Contact";
// import Projects from "./components/projects/Projects";

const Home = React.lazy(()=> import('./components/home/Home.jsx'));
const About = React.lazy(()=> import('./components/about/About.jsx'));
const Resume = React.lazy(()=> import('./components/resume/Resume.jsx'));
const Contact = React.lazy(()=> import('./components/contact/Contact.jsx'));
const Projects = React.lazy(()=> import('./components/projects/Projects.jsx'));

function App() {
  return (
    <div>
      <Suspense fallback={<div>Loading...</div>}>
      <Sidebar/>
      <main className="main">
        <Home/>
        <About/>
        <Projects/>
        <Resume/>
        <Contact/> 
      </main>
      </Suspense>
    </div>
  );
}

export default App;
